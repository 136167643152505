<template>
  <modal-wrapper :id="id" hide-footer size="md">
    <template #title>
      Informazioni aggiuntive
    </template>
    <template #body>
      <div v-html="info" />
    </template>
  </modal-wrapper>
</template>

<script>
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ModalDocInfo',
  components: {
    ModalWrapper,
  },
  props: {
    id: String,
    info: String,
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>

</style>
